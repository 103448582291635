//import React from "react";
import { useState } from "react";
import { CgShoppingBag } from "react-icons/cg";
import { GoThreeBars } from "react-icons/go";
import "../Css/header.css";
import logo from "../image/logo.png";
function Header() {
  const [showMenu, setShowMenu] = useState(true);
  const toggleMenu = () => {
    setShowMenu((showMenu) => !showMenu);
  };
  return (
    <>
      <div className="container">
        <div className="logo">
          <img src={logo} alt="Mon logo" />
          <span>amazon</span>
        </div>

        <div className="right">
          <div className="bars" onClick={toggleMenu}>
            <GoThreeBars />
          </div>
          <div
            className="menu"
            style={{ display: showMenu ? "inherit" : "none" }}
          >
            <ul>
              <li>Collections</li>
              <li>Brands</li>
              <li>New</li>
              <li>Sales</li>
              <li>ENG</li>
            </ul>
          </div>

          <input
            placeholder="Search..."
            type="text"
            name="search"
            id="search"
            className="search"
          />
          <CgShoppingBag className="cart" />
        </div>
      </div>
    </>
  );
}
export default Header;
