import alex from "../image/alex.png";
import nat from "../image/nat.png";
export const Testimoniall = [
  {
    Name: "John Aledon",
    Comment:
      "Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    img: alex,
  },
  {
    Name: "Natasha",
    Comment:
      "Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    img: nat,
  },
  {
    Name: "Alex Harmonzi",
    Comment:
      "Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    img: nat,
  },
  {
    Name: "Adama Harmonzi",
    Comment:
      "Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    img: nat,
  },
];
