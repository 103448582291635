//import React from "react";
import "../Css/Hero.css";
//import data_img from "../bella.jpg";
import { RiShoppingBagFill } from "react-icons/ri";
import { BsArrowRight } from "react-icons/bs";
import { motion } from "framer-motion";
export default function Hero() {
  const transition = { duration: 3, type: "spring" };
  return (
    <>
      <div className="containerr">
        <div className="h_sides">
          <span className="text1">Skin protection cream </span>
          <div className="text2">
            <span>Trendy collection</span>
            <span>
              Seedily say has suitable disposal and boy. <br />
              Exercise joy man children rejoiced.
            </span>
          </div>
        </div>
        {/*transition of blue circle */}
        <div className="center">
          <motion.div
            initial={{ bottom: "2rem" }}
            whileInView={{ bottom: "0rem" }}
            className="bluecercle"
            transition={transition}
          ></motion.div>
          <motion.img
            src="https://amazon-3l2.pages.dev/static/media/hero.7e2be00982208588722d.png"
            alt=""
            className="immg"
            width={600}
            transition={transition}
            initial={{ bottom: "-2rem" }}
            whileInView={{ bottom: "0rem" }}
          />
          <motion.div
            className="card2"
            transition={transition}
            initial={{ right: "4%" }}
            whileInView={{ right: "2%" }}
          >
            <RiShoppingBagFill />
            <div className="signup">
              <span>Best Signup Offers</span>
              <div>
                <BsArrowRight />
              </div>
            </div>
          </motion.div>
        </div>
        <div className="h_sides">
          <div className="traffic">
            <span>1.5m</span>
            <span>Monthly Traffic</span>
          </div>
          <div className="customers">
            <span>100k</span>
            <span>Happy Customers</span>
          </div>
        </div>
      </div>
    </>
  );
}
