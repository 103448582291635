import React, { useState } from "react";
import "../Css/productall.css";
import panner from "../image/panner.png";
import { productsData } from "./data_slider";
import Product from "./products";
import { useAutoAnimate } from "@formkit/auto-animate/react";
const Productsall = () => {
  const [parent] = useAutoAnimate();
  const [MenuProducts, setMenuProducts] = useState(productsData);
  const filter = (type) => {
    setMenuProducts(productsData.filter((product) => product.type === type));
  };
  return (
    <>
      <div className="container_all">
        <img src={panner} alt="" />
        <h1>Our Featured Products</h1>
        <div className="productall">
          <ul className="product_menu">
            <li onClick={() => setMenuProducts(productsData)}>All</li>
            <li onClick={() => filter("Skin Care")}>Skin Care</li>
            <li onClick={() => filter("Conditioner")}>Conditioners</li>
            <li onClick={() => filter("Foundation")}>Foundations</li>
          </ul>
          <div className="list_product" ref={parent}>
            {MenuProducts.map((product) => {
              return (
                <div key={product.cle} className="pproduct">
                  <Product
                    nom={product.Nom}
                    detail={product.detail}
                    price={product.price}
                    img={product.img}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
export default Productsall;
