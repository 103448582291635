import React from "react";
//import ReactCompareImage from "react-compare-image";
import "../Css/virtual.css";
import shade from "../image/shade.png";
import Experiency from "./experiency";
// console.log(<ReactCompareImage leftImage={After} rightImage={Before} />);
const Virtual = () => {
  return (
    <>
      <div className="Virtual">
        <div className="left_virtual">
          <span>Virtual Try-On</span>
          <span>Never Buy The Wrong Shade Again!</span>
          <span>Try Now!</span>
          <img src={shade} alt="" />
        </div>
        <div className="right_virtual">
          <div className="_wrapper">
            <Experiency />
          </div>
        </div>
      </div>
    </> /*
              <ReactCompareImage
                leftImageAlt="gvhgvg"
                rightImageAlt="hbhg"
                leftImage={After}
                rightImage={Before}
              />
          */
  );
};
export default Virtual;
