//import React from "react";
import "../Css/slider.css";
//import { données, productsDat } from "../components/data_slider";
export default function Product(props) {
  //console.log(props.img);
  return (
    <>
      <div className="l_slide">
        <div className="name">
          <span>{props.nom}</span>
          <span>{props.detail} </span>
        </div>
        <span>{props.price}$</span>
        <button>Shop Now</button>
      </div>
      <img className="img_sld" src={props.img} alt="" />
    </>
  );
}
