import "./App.css";
import Footer from "./components/footer";
//import Experiency from "./components/experiency";
import Header from "./components/Hearder";
import Hero from "./components/Hero";
import Productsall from "./components/productsall";
import Slider from "./components/slider";
import Testimonial from "./components/testimonial";
import Virtual from "./components/virtual";

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <Slider />
      <Virtual />
      <Productsall />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default App;
