//import React from "react";
import "../Css/slider.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { données } from "../components/data_slider";
import Product from "./products";
export default function Slider() {
  return (
    <>
      <div className="s_container">
        <Swiper
          modules={[Pagination, Navigation]}
          loopFillGroupWithBlank={true}
          navigation={true}
          className="swiper"
          slidesPerView={3}
          spaceBetween={40}
          slidesPerGroup={1}
          loop={true}
          breakpoints={{ 640: { slidesPerView: 3 }, 0: { slidesPerView: 1 } }}
        >
          {données.map((donnée, index) => {
            // console.log(donnée.cle);
            return (
              // <>
              <SwiperSlide key={donnée.cle.toString()}>
                <Product
                  nom={donnée.Nom}
                  detail={donnée.detail}
                  price={donnée.price}
                  img={donnée.img}
                />
              </SwiperSlide>
              // </>
            );
          })}
        </Swiper>
      </div>
    </> /**/
    /* {données.map((donnée) => {
            return (
              <>
                <SwiperSlide key={donnée.img}>
                  <div className="Slide">
                    <div className="r_slide">
                      <span>{donnée.Nom} </span>
                      <span>{donnée.detail} </span>
                      <span>{donnée.price} </span>
                      <button>Shope Now</button>
                    </div>
                    <div className="l_slide">
                      <img src={donnée.img} alt="" />
                    </div>
                  </div>
                </SwiperSlide>
              </>
            );
          })}*/
  );
}
