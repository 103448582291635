import img1 from "../image/img1.png";
import img2 from "../image/img2.png";
import img3 from "../image/img3.png";
import img4 from "../image/img4.png";
import img5 from "../image/img5.png";
import img6 from "../image/img6.png";
import img7 from "../image/img7.png";
import img8 from "../image/img8.png";
import img9 from "../image/img9.png";
import img10 from "../image/img10.png";
import img11 from "../image/img11.png";
import img12 from "../image/img12.png";
import img13 from "../image/img13.png";
import img14 from "../image/img14.png";

export const données = [
  {
    Nom: "Skin",
    detail: "Super Skin Care",
    price: "25",
    img: img1,
    cle: 110,
  },
  {
    Nom: "Skin",
    detail: "Super Skin Care",
    price: "30",
    img: img2,
    cle: 1,
  },
  {
    Nom: "Nature",
    detail: "Super Skin Care",
    price: "25",
    img: img3,
    cle: 2,
  },
  {
    Nom: "Skin",
    detail: "Super Skin Care",
    price: "30",
    img: img2,
    cle: 3,
  },
];
export const productsData = [
  {
    Nom: "Skin",
    detail: "Super Skin Care",
    price: "25",
    img: img1,
    type: "Skin Care",
    cle: 4,
  },
  {
    Nom: "Skin",
    detail: "Super Skin Care",
    price: "30",
    img: img2,
    type: "Skin Care",
    cle: 5,
  },
  {
    Nom: "Nature",
    detail: "Super Skin Care",
    price: "25",
    img: img3,
    type: "Skin Care",
    cle: 6,
  },
  {
    Nom: "Foundation",
    detail: "Super Skin Care",
    price: "25",
    img: img4,
    type: "Foundation",
    cle: "7",
  },
  {
    Nom: "Conditioner",
    detail: "Super Skin Care",
    price: "30",
    img: img5,
    type: "Conditioner",
    cle: 8,
  },
  {
    Nom: "Nature",
    detail: "Super Skin Care",
    price: "25",
    img: img6,
    type: "Skin Care",
    cle: 9,
  },
  {
    Nom: "Conditioner",
    detail: "Best Conditioner",
    price: "30",
    img: img7,
    type: "Conditioner",
    cle: 10,
  },
  {
    Nom: "Conditioner",
    detail: "Best Conditioner",
    price: "25",
    img: img8,
    type: "Conditioner",
    cle: 11,
  },
  {
    Nom: "Conditioner",
    detail: "Best Conditioner",
    price: "30",
    img: img9,
    type: "Conditioner",
    cle: 12,
  },
  {
    Nom: "Nature",
    detail: "Super Skin Care",
    price: "25",
    img: img10,
    type: "Skin Care",
    cle: 13,
  },
  {
    Nom: "Foundation",
    detail: "Nourish Your Skin",
    price: "12",
    img: img11,
    type: "Foundation",
    cle: 14,
  },
  {
    Nom: "Foundation",
    detail: "Nourish Your Skin",
    price: "25",
    img: img12,
    type: "Foundation",
    cle: 15,
  },
  {
    Nom: "Foundation",
    detail: "Nourish Your Skin",
    price: "250",
    img: img13,
    type: "Foundation",
    cle: 16,
  },
  {
    Nom: "Foundation",
    detail: "Nourish Your Skin",
    price: "250",
    img: img14,
    type: "Foundation",
    cle: 17,
  },
];
