import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "../Css/testimonial.css";
import TestimonialHero from "../image/testimonialHero.png";
import { Testimoniall } from "./Testimonial_data";
const Testimonial = () => {
  return (
    <>
      <div className="testimonials">
        <div className="testimonial_wrapper">
          <div className="container_testimonial">
            <span>Top Rated</span>
            <span>
              Seedly say has suitable disposal and boy. Exercise joy man
              children rejoiced.
            </span>
          </div>

          <img src={TestimonialHero} alt="" />

          <div className="container_testimonial">
            <span>100k</span>
            <span>Happy Coustomers With Us</span>
          </div>
        </div>
      </div>
      <div className="review">Reviews</div>
      <div className="coursel">
        <Swiper
          slidesPerView={3}
          slidesPerGroup={1}
          spaceBetween={20}
          breakpoints={{
            856: { slidesPerView: 3 },
            640: { slidesPerView: 2 },
            0: { slidesPerView: 1 },
          }}
          className="tcarousel"
        >
          {Testimoniall.map((testimon) => {
            return (
              <SwiperSlide key={testimon.Name}>
                <div className="testmo">
                  <img src={testimon.img} alt="" />
                  <span>{testimon.Comment} </span>
                  <hr />
                  <span>{testimon.Name} </span>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
};
export default Testimonial;
