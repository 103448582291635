import React from "react";
import "../Css/footer.css";
import logo from "../image/logo.png";
import {
  InboxIcon,
  PhoneIcon,
  LocationMarkerIcon,
  LoginIcon,
  UsersIcon,
  LinkIcon,
} from "@heroicons/react/outline";
const Footer = () => {
  return (
    <div className="cFooterWrapper">
      <hr />
      <div className="cFooter">
        <div className="cLogo">
          <img src={logo} alt="" />
          <span>amazon</span>
        </div>

        <div className="block">
          <div className="details">
            <span>Contacts Us</span>
            <span className="pngLine">
              <LocationMarkerIcon className="icon" />
              111 north avenue Orlando, Floride 31392
            </span>
            <span className="pngLine">
              <PhoneIcon className="icon" />
              352-306-4415
            </span>
            <span className="pngLine">
              <InboxIcon className="icon" />
              support@amazon.com
            </span>
          </div>
        </div>
        <div className="block">
          <div className="details">
            <span>Account</span>
            <span className="pngLine">
              <LoginIcon className="icon" />
              Sign In
            </span>
          </div>
        </div>
        <div className="block">
          <div className="details">
            <span>Company</span>
            <span className="pngLine">
              <UsersIcon className="icon" />
              About Us
            </span>
          </div>
        </div>
        <div className="block">
          <div className="details">
            <span>Resources</span>
            <span className="pngLine">
              <LinkIcon className="icon" />
              Savety Privacy & Terms
            </span>
          </div>
        </div>
      </div>
      <div className="copyrigth">
        Copyright ©2022 by Amazon, Inc.
        <br /> All rights reserved.
      </div>
    </div>
  );
};
export default Footer;
